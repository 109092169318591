<template>
  <el-form ref="form" :model="value" size="mini" :rules="formRules" label-position="top">
    <el-form-item :label="$locale.etl_editor.form.fields.name" prop="name">
      <el-input v-model="value.name" :placeholder="$locale.etl_editor.form.fields.name" size="mini"></el-input>
    </el-form-item>

    <slot></slot>
  </el-form>
</template>

<script>
export default {
  name: 'GroupUpdate',

  props: {
    value: {
      type: Object,
      default: null
    },
    title: String
  },

  watch: {
    value () {
      this.localValue = this.value
    }
  },

  data () {
    return {
      localValue: this.value,
      formRules: {
        name: [
          { required: true, message: 'Please input Name', trigger: 'change' }
        ]
      }
    }
  },

  methods: {
    submit (save) {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          await save()
        }
      })
    }
  }
}
</script>

<style type="text/css">

</style>
